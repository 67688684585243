import React from "react";
import "../../src/policy.css";
import { Link, useNavigate } from "react-router-dom"; // Assuming you're using React Router

const PrivacyPolicy = () => {
  document.title = "Privacy Policy";
  const navigate = useNavigate();

  return (
    <>
      <section className="breadcrumb-area pt-10px bg-gray">
        <div className="container">
          <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between pb-10px">
            <div className="section-heading">
              <h2 className="fs-24 text-black">Privacy Policy</h2>
            </div>
            <ul className="generic-list-item-black generic-list-item-arrow d-flex flex-wrap align-items-center">
              <li>
                <Link
                  to="/"
                  onClick={() => (window.location.href = "/")}
                  className="text-black"
                >
                  Home
                </Link>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </div>
          {/* end breadcrumb-content */}
        </div>
        {/* end container */}
      </section>
      <div className="policy-page">
        <div className="policy-container">
          <header>
            <h2>Privacy Policy for Lens By HR Fusion</h2>
            <p className="effective-date">
              <strong>Effective Date:</strong> 24th January 2025
            </p>
          </header>

          <section className="section">
            <h3>Introduction</h3>
            <p>
              Aitsil ("we," "our," or "us") is committed to protecting the
              privacy of its users. This Privacy Policy outlines how we collect,
              use, and safeguard personal information in connection with the
              Lens By HR Fusion App ("the App"). By using the App, you agree to
              the terms of this Privacy Policy.
            </p>
          </section>

          <section className="section">
            <h4>1. Information We Collect</h4>
            <div className="sub-section">
              <p>
                <strong>1.1 Photos of Staff:</strong>
              </p>
              <ul>
                <li>
                  Photos are collected solely to enable facial recognition for
                  attendance tracking.
                </li>
              </ul>
              <p>
                <strong>1.2 Attendance Logs:</strong>
              </p>
              <ul>
                <li>
                  Punch-in and punch-out records, including timestamps, are
                  collected to maintain accurate attendance logs.
                </li>
              </ul>
              <p>
                <strong>1.3 Admin Login Information:</strong>
              </p>
              <ul>
                <li>
                  Admin credentials, such as usernames and passwords, are
                  collected for authentication and access control.
                </li>
              </ul>
            </div>
          </section>

          <section className="section">
            <h4>2. How We Use the Information</h4>
            <div className="sub-section">
              <p>
                <strong>2.1 Attendance Management:</strong>
              </p>
              <ul>
                <li>
                  Photos and facial recognition data are processed to record and
                  manage attendance accurately.
                </li>
              </ul>
              <p>
                <strong>2.2 Log Maintenance:</strong>
              </p>
              <ul>
                <li>
                  Attendance logs are maintained for record-keeping, compliance,
                  and reporting purposes.
                </li>
              </ul>
              <p>
                <strong>2.3 Account Management:</strong>
              </p>
              <ul>
                <li>
                  Admin login credentials are used to authenticate and provide
                  secure access to the App.
                </li>
              </ul>
              <p>
                <strong>2.4 Improvement of Services:</strong>
              </p>
              <ul>
                <li>
                  Device and app usage information may be analyzed to enhance
                  user experience and ensure system performance.
                </li>
              </ul>
            </div>
          </section>

          <section className="section">
            <h4>3. Data Security</h4>
            <div className="sub-section">
              <p>
                We implement industry-standard security measures to protect
                personal information from unauthorized access, alteration, or
                disclosure. This includes encryption, access controls, and
                regular security assessments.
              </p>
            </div>
          </section>

          <section className="section">
            <h4>4. Data Retention</h4>
            <div className="sub-section">
              <p>
                Photos and attendance logs are retained only for as long as
                necessary to fulfill attendance management purposes. Once the
                data is no longer required, it is securely deleted.
              </p>
            </div>
          </section>
          <section className="section">
            <h4>5. User Rights</h4>
            <div className="sub-section">
              <p>
                <ul>
                  <li>Attendance Management:</li>
                  <ul className="ml-4">
                    <li>
                      Photos and facial recognition data are processed to record
                      and manage attendance accurately.
                    </li>
                  </ul>
                </ul>
              </p>
            <p>
              For data requests or concerns, please contact us at{" "}
              <a href="mailto:marketing@aitsil.in" className="text-primary">
                marketing@aitsil.in
              </a>
              .
            </p>
            </div>
          </section>
          <section className="section">
            <h4>6. Third-Party Sharing</h4>
            <div className="sub-section">
              <p>
                <ul>
                  <li>
                    We do not share personal information with third parties
                    except where required by law or to protect our legal rights.
                  </li>
                </ul>
              </p>
            </div>
          </section>
          <section className="contact mb-4">
            <h4>7. Contact Us</h4>
            <div className="sub-section">
              <p>
                If you have any questions, concerns, or requests regarding this
                Privacy Policy, please contact us at:
              </p>
              <p>
                <span className="text-dark f-w-600">Email: </span>
                <a href="mailto:marketing@aitsil.in" className="text-primary">
                  marketing@aitsil.in
                </a>
              </p>
            </div>
          </section>

          <section className="acknowledgment">
            <h3>Acknowledgment</h3>
            <p>
              By using the Lens By HR Fusion App, you acknowledge that you have
              read, understood, and agreed to this Privacy Policy.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
