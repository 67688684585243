import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive(!active);
  };

  return (
    <>
      <header className="header-menu-area bg-white">
        <div className="header-menu-content pr-150px pl-150px bg-white">
          <div className="container-fluid">
            <div className="main-menu-content">
              <div className="row align-items-center">
                <div className="col-lg-1">
                  <div className="logo-box">
                    <Link
                      to="/"
                      onClick={() => (window.location.href = "/")}
                      className="logo"
                    >
                      <img
                        src="img/image.png"
                        height={50}
                        width={80}
                        alt="logo"
                      />
                    </Link>
                    <div className="user-btn-action">
                      <div
                        className="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Main menu"
                        onClick={() => toggle()}
                      >
                        <i className="la la-bars" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-11">
                  <div className="menu-wrapper">
                    <nav className="main-menu">
                      <ul>
                        <li>
                          <Link
                            to="/"
                            onClick={() => (window.location.href = "/")}
                          >
                            Home
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/companyDetails">Company</Link>
                        </li> */}
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/gallery">gallery</Link>
                        </li>
                        <li>
                          <Link to="/career">Career</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        <li>
                          <Link to="/privacypolicy">Privacy Policy</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`off-canvas-menu custom-scrollbar-styled main-off-canvas-menu ${
            active === true ? `active` : ``
          }`}
        >
          <div
            className="off-canvas-menu-close main-menu-close icon-element icon-element-sm shadow-sm"
            data-toggle="tooltip"
            data-placement="left"
            title="Close menu"
            onClick={() => toggle()}
          >
            <i className="la la-times" />
          </div>
          <ul className="generic-list-item off-canvas-menu-list pt-90px">
            <ul>
              <li>
                <Link to="/" onClick={() => (window.location.href = "/")}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/gallery">gallery</Link>
              </li>
              <li>
                <Link to="/career">Career</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </ul>
        </div>
        {/* end off-canvas-menu */}
        <div className="mobile-search-form">
          <div className="d-flex align-items-center">
            {/* <form method="post" className="flex-grow-1 mr-3">
              <div className="form-group mb-0">
                  <input className="form-control form--control pl-3" type="text" name="search"
                      placeholder="Search for anything">
                  <span className="la la-search search-icon"></span>
              </div>
          </form> */}
            <div className="search-bar-close icon-element icon-element-sm shadow-sm">
              <i className="la la-times" />
            </div>
            {/* end off-canvas-menu-close */}
          </div>
        </div>
        {/* end mobile-search-form */}
        <div className="body-overlay" />
      </header>
    </>
  );
};

export default Header;
